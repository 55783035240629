import React from "react";
import "@aws-amplify/ui-react/styles.css";

import Layout from "../components/layout/index";
import {Container} from "../common/";
import Dashboard from "../components/dashboard";
import Search from "./guides"
function Index() {
  return (
    <div>
      <Search />
    </div>
  );
}

export default Index;
